<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Chi tiết refer</h6>
              </template>

              <div>
                <b-card-group deck>
                  <b-card
                      border-variant="primary"
                      header="Sender"
                      header-bg-variant="primary"
                      header-text-variant="white"
                      align="center"
                  >
                    <b-card-text>
                      <span> SĐT : {{ this.refer.sender.phone }} </span> <br />
                      <span> User ID: {{ this.refer.sender.uuid }} </span><br />
                      <span >
                        Họ tên: {{ this.refer.sender.name }}
                      </span>
                    </b-card-text>
                  </b-card>

                  <b-card
                      border-variant="info"
                      header="Receiver"
                      header-border-variant="info"
                      header-bg-variant="info"
                      header-text-variant="white"
                      align="center"
                  >
                    <b-card-text>
                      <span> SĐT : {{ this.refer.receiver.phone }} </span> <br />
                      <span> User ID: {{ this.refer.receiver.uuid }} </span><br />
                      <span>
                        Họ tên: {{ this.refer.receiver.name }}
                      </span>
                    </b-card-text>
                  </b-card>

                  <b-card
                      border-variant="warning"
                      header="Thời gian tham gia"
                      header-border-variant="warning"
                      header-bg-variant="warning"
                      header-text-variant="white"
                      align="center"
                  >
                    <b-card-text>{{ this.refer.created_at | formatDateTime }}.</b-card-text>
                  </b-card>

                  <b-card
                      border-variant="success"
                      header="Thời gian hoàn thành"
                      header-border-variant="success"
                      header-bg-variant="success"
                      header-text-variant="white"
                      align="center"
                  >
                    <b-card-text>{{ this.refer.updated_at | formatDateTime }}.</b-card-text>
                  </b-card>


                </b-card-group>
              </div>

              <b-table
                  table-class="datatable-table"
                  hover
                  tbody-tr-class="datatable-row"
                  thead-tr-class="datatable-row"
                  thead-class="datatable-head"
                  details-td-class="datatable-cell"
                  :items="missions"
                  :fields="render_missions"
              >

                <template #cell(status)="data">
                  <span v-if=" data.item.status == 1" class="badge mr-1 badge-success"> Hoàn thành</span>
                  <span v-else class="badge mr-1 badge-warning"> Chưa hoàn thành</span>
                </template>

                <template #cell(updated_at)="data">
                  <span> {{ data.item.updated_at | formatDateTime }} </span> <br />
                </template>


              </b-table>

              <table role="table" aria-busy="false" aria-colcount="4" class="table b-table datatable-table table-hover" >
                <thead role="rowgroup" class="datatable-head">
                  <tr role="row" class="datatable-row">
                    <th role="columnheader" scope="col" aria-colindex="1" class="datatable-cell">
                      <div>STT</div>
                    </th>
                    <th role="columnheader" scope="col" aria-colindex="1" class="datatable-cell">
                      <div>Quà tặng</div>
                    </th>
                    <th role="columnheader" scope="col" aria-colindex="2" class="datatable-cell">
                      <div>Trạng thái nhận quà</div>
                    </th>
                    <th role="columnheader" scope="col" aria-colindex="3" class="datatable-cell">
                      <div>Lý do thất bại </div>
                    </th>
                    <th role="columnheader" scope="col" aria-colindex="4" class="datatable-cell">
                      <div>Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody role="rowgroup">
                  <tr role="row" class="datatable-row" v-for="(gift, index) in gifts" :key="index">
                    <td aria-colindex="1" role="cell" class="datatable-cell">
                      {{ gift.type }}
                    </td>
                    <td aria-colindex="2" role="cell" class="datatable-cell">
                      <span class="badge mr-1 badge-success">{{ gift.title }}</span>
                    </td>
                    <td aria-colindex="3" role="cell" class="datatable-cell">
                      <span v-if="gift.status == 0">
                          Chưa trả quà
                      </span>
                      <span v-else-if="gift.status == 1">
                          Đã trả
                      </span>
                      <span v-else>
                          Lỗi
                      </span>
                    </td>
                    <td aria-colindex="4" role="cell" class="datatable-cell">
                      -
                    </td>
                    <td aria-colindex="4" role="cell" class="datatable-cell">
                      -
                    </td>
                  </tr>

                </tbody>
              </table>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const WalletCampaignRepository = RepositoryFactory.get("Campaign");

export default {
  mixins: [Common],
  data() {
    return {
      id: this.$route.params.id,
      render_missions: [
        {
          key: "title",
          label: "Nhiệm vụ",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "status",
          label: "Trạng thái hoàn thành",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "updated_at",
          label: "Thời gian hoàn thành",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "note",
          label: "Lý do thất bại",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      render_gifts: [
        {
          key: "type",
          label: "Loại",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "title",
          label: "Quà tặng",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "status",
          label: "Trạng thái nhận quà",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "note",
          label: "Lý do thất bại",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "Hành động",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      missions: [],
      refer: {
        sender: {
          phone: '',
          uuid: '',
          name: ''
        },
        receiver: {
          phone: '',
          uuid: '',
          name: ''
        }
      },
      items: [],
      gifts: []
    };
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Thống kê" },
      { title: "Chi tiết thử thách", route: "/campaign/report/refers" },
    ]);
  },
  watch: {
    currentPage() {
      this.loadData();
    },
  },
  methods: {
    async loadData() {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await WalletCampaignRepository.getReferDetail(this.id);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        this.refer = body.data;
        this.missions = this.refer.steps;
        this.gifts = this.refer.gifts;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    buildStep (item) {
      if (item.status.value === 1) {
        return '-';
      } else {
        let title = '';
        item.steps.forEach(step => {
          if (step.is_current) {
            title = step.title;
          }
        });
        return title;
      }
    },

  },
};
</script>

<style >
.set-width {
  width: 30% !important;
}
select.form-control { -webkit-appearance: menulist; }
</style>
